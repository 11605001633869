<script setup>
import { useMediaQuery } from "@vueuse/core"
import { defineEmits, defineProps, ref, watch } from "vue"
import Arrow from "./icons/arrowS.vue"
import MenuS from "./icons/menuS.vue"
import templateIcon from "./icons/template.vue"

const props = defineProps({
  modules: {
    type: Object,
    required: true,
  },

  title: {
    type: String,
    required: true,
  },
});
const isLargeScreen = useMediaQuery("(max-width: 768px)");

const showModulesMovil = ref(true);
const emit = defineEmits(["lessonSelected"]);
const activeModuleIndex = ref(null);
const activeLessonIndex = ref(null);
function selectLesson(lesson, indexLesson) {
  emit("lessonSelected", lesson);
  activeLessonIndex.value = indexLesson;
}

function selectModule(index) {
  activeModuleIndex.value = index;
}
watch(isLargeScreen, (newValue) => {
  if (newValue) {
    showModulesMovil.value = false;
  }
});
</script>

<template>
  <div class="sidebar border-r-primary-dark py-3">
    <div
      class="sidebar-header border-primary-dark pt-2 pb-2 mb-lg-3 d-flex justify-content-start"
    >
      <div class="w-33">
        <div class="progress-circle relative">
          <svg viewBox="0 0 36 36" class="circular-chart green">
            <path
              class="circle-bg"
              d="M18 2.0845
              a 15.9155 15.9155 0 0 1 0 31.831
              a 15.9155 15.9155 0 0 1 0 -31.831"
            />
            <path
              class="circle"
              stroke-dasharray="1, 100"
              d="M18 2.0845
              a 15.9155 15.9155 0 0 1 0 31.831
              a 15.9155 15.9155 0 0 1 0 -31.831"
            />
          </svg>
          <p class="percentage orange-text">1%</p>
        </div>
      </div>
      <div class="text-start w-100">
        <h6>{{ props.title }}</h6>
        <p class="fs-6 gray-text">1 de 77 lecciones terminadas</p>
      </div>
      <div
        @click="showModulesMovil = !showModulesMovil"
        class="w-33 menu-xs align-items-center justify-content-end mb-3 me-3"
      >
        <MenuS />
      </div>
    </div>
    <div v-if="showModulesMovil">
      <div
        v-for="(module, index) in props.modules"
        :key="index"
        class="border-primardark"
      >
        <h6
          @click="selectModule(index)"
          :class="{ 'bg-cousers': activeModuleIndex === index }"
          class="module-title d-flex justify-content-between px-3 py-3 mb-0"
        >
          <div>
            <templateIcon
              :class="{ 'color-active': activeModuleIndex === index }"
              class="me-2"
            />
            {{ module.title }}
          </div>
          <div class="d-flex align-items-center gap-3">
            <p class="mb-0 text-length" v-text="`1/${module.lessons.length}`" />
            <Arrow :class="{ 'rotate-180': activeModuleIndex === index }" />
          </div>
        </h6>

        <ul
          class="list-unstyled mb-0 content-lessons"
          :class="{ active: activeModuleIndex === index }"
        >
          <li
            v-for="(lesson, indexLesson) in module.lessons"
            :key="indexLesson"
            @click="selectLesson(lesson, indexLesson)"
            class="mb-0"
          >
            <p
              :class="{ 'active-lesson': activeLessonIndex === indexLesson }"
              class="lesson-title relative mb-0"
            >
              {{ lesson.title }}
            </p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<style scoped>
.w-33 {
  width: 33%;
}
.sidebar {
  height: calc(100vh - 56px);
  overflow-y: auto;
  /* padding: 20px; */
}
.p-20 {
  padding: 20px;
}
.sidebar-header {
  text-align: center;
}
.progress-circle {
  position: relative;
  /* width: 100px;
  height: 100px; */
  /* margin: 10px auto; */
}
.circular-chart {
  /* display: block;
  margin: 10px auto;
  max-width: 100%;
  max-height: 250px; */
  width: 74%;
}
.circle-bg {
  fill: none;
  stroke: #272a2f;
  stroke-width: 3.8;
}
.circle {
  fill: none;
  stroke-width: 2.8;
  stroke-linecap: round;
  animation: progress 1s ease-out forwards;
}
.green .circle {
  stroke: #da5050;
}
.percentage {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 0.8em;
  margin: 0;
}
@keyframes progress {
  0% {
    stroke-dasharray: 0, 100;
  }
}
.module-title {
  font-weight: bold;
  display: flex;
  align-items: center;
  cursor: pointer;
}
/* .module-title::before {
  content: "+";
  display: inline-block;
  margin-right: 8px;
  color: green;
} */

.sidebar ul {
  padding-left: 0;
}
.sidebar ul li {
  padding: 16px 45px;
  border-top: 1px solid #333333;
  background: #272a2f;
  cursor: pointer;
  align-items: center;
}
.sidebar ul li a {
  color: #28a745;
  text-decoration: none;
}
.lesson-title {
}
.lesson-title::before {
  content: "";
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid green;
  display: inline-block;
  margin-right: 10px;
  color: green;
  margin-bottom: -3px;
}
.lesson-title.active-lesson::before {
  background: green;
  border: 1px solid green;
}
.text-length {
  font-size: 12px;
  font-weight: 400;
}
.rotate-180 {
  transform: rotate(180deg);
  transition: all;
  transition-duration: 0.3s;
}
.color-active {
  color: #28a745;
}
.content-lessons {
  max-height: 0;
  overflow: hidden;
  transition: all;
  transition-duration: 0.3s;
}
.content-lessons.active {
  max-height: max-content;
}
.menu-xs {
  display: none;
}

@media (max-width: 768px) {
  .menu-xs {
    display: flex;
  }
}
</style>
