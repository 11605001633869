import '@/assets/styles/main.css'
import { Auth0Plugin } from '@auth0/auth0-vue'
import '@fortawesome/fontawesome-free/css/all.min.css'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import { createPinia } from 'pinia'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'


const pinia = createPinia();


createApp(App)
  .use(router)
  .use(pinia)
  .use(
    new Auth0Plugin ({
      domain: `${process.env.VUE_APP_AUTH0_DOMAIN}`,
      clientId: `${process.env.VUE_APP_AUTH0_CLIENTID}`,
      authorizationParams: {
        audience: `${process.env.VUE_APP_AUTH0_AUDIENCE}`,
        redirect_uri: window.location.origin
      }
    })
)
.mount('#app')


