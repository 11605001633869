<template>
  <nav class="navbar navbar-expand-lg   border-primary-dark" >
    <a class="navbar-brand pa-10" href="/test">Shleper</a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNav">
      <ul class="navbar-nav ms-auto">
        <li class="nav-item dropdown" v-if="isAuthenticated">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <img :src="user?.picture" alt="Profile Picture" class="profile-picture-sm"> {{ user.name }}
          </a>
          <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
            <li><a class="dropdown-item" href="#" @click="logOut">Logout</a></li>
          </ul>
        </li>
        <li class="nav-item" v-else>
          <a class="nav-link" href="#" @click="login">Login</a>
        </li>
      </ul>
    </div>
</nav>
</template>

<script setup>

import { useAuth0, } from '@auth0/auth0-vue'

const { loginWithRedirect, logout, user, isAuthenticated } = useAuth0();

const login = async () => {
  await loginWithRedirect()
}

const logOut = async () => {
  sessionStorage.removeItem('token')
  await logout()
}

</script>

<style scoped>
/* .navbar {
  background-color: #28a745;
}
.navbar-brand, .nav-link, .navbar-text {
  color: #ffffff !important;
  font-weight: bold;
} */
 /* Navbar styling */
.navbar {
  background-color: #343a40; /* Dark gray background */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.navbar-brand {
  font-weight: bold;
  font-size: 1.5rem;
  color: #f8f9fa; /* Light color for contrast */
}

.nav-link {
  color: #f8f9fa; /* Light color for links */
  transition: color 0.3s ease-in-out; /* Smooth transition for hover effect */
}

.nav-link:hover {
    color: #17a2b8; /* Light blue for hover effect */
}

.profile-picture-sm {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 8px;
}

.dropdown-menu {
    background-color: #343a40; /* Same dark gray background */
    border: 1px solid #495057; /* Slightly lighter border */
}

.dropdown-item {
    color: #f8f9fa;
}

.dropdown-item:hover {
    background-color: #495057; /* Slightly lighter background on hover */
    color: #f8f9fa;
}

.navbar-toggler {
    border-color: #f8f9fa; /* Light color for the toggler */
}
</style>
